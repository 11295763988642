html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-family: 'SF Pro Display', 'San Francisco', -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
  font-feature-settings: "liga";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--color-bg-main);

  /* Disables pull-to-refresh and overscroll glow effect. Still keeps swipe navigations.
  Safari doesn't support it?.. */
  overscroll-behavior-y: none;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */
